const WithTelegram = ({ children }) => {
    const Telegram = window.Telegram;
    if (!Telegram) {
      throw new Error('Telegram is not available in this context');
    }
    window.Telegram.WebApp.disableVerticalSwipes();
    if (!window.Telegram.WebApp.isExpanded) {
      window.Telegram.WebApp.expand();
    }
    return children;
  };

export default WithTelegram;