import { API_RECOMANDATIONS_ENGINES } from "../../../helpers/config";
import { _INIT, ACTION_GET } from "../../api-store/getpage"

export const GET_RECOMANDATION_MARKETPLACE = 'GET_RECOMANDATION_MARKETPLACE';
export const SET_RECOMANDATION_MARKETPLACE = 'SET_RECOMANDATION_MARKETPLACE';
export const UPDATE_DATA_RECOMANDATIONS = "UPDATE_RECOMANDATION_MARKETPLACE";


export const recomandation = store => {
    store.on(_INIT, () => ({
        recomandations: {
            count: 0,
            results: []
        }
    }))

   store.on(SET_RECOMANDATION_MARKETPLACE,({}, data) => ({
       recomandations: { ...data },
     })
   );
   store.on(UPDATE_DATA_RECOMANDATIONS, ({}, data, {dispatch}) => {
     dispatch(GET_RECOMANDATION_MARKETPLACE)
   });

   store.on(GET_RECOMANDATION_MARKETPLACE, async ({},data, {dispatch}) => {
        // Fetch data from API
        const params = {
          url: API_RECOMANDATIONS_ENGINES,
          dataRequst: res => {
            if (res === undefined) return;
            dispatch(SET_RECOMANDATION_MARKETPLACE, res);
          }
        };
        dispatch(ACTION_GET, params)
    })
}